//colors
$primary120: #2340CC;
$primary100: #2748E3;
$primary10: #E9F2FF;
$primary8: #EEF1FD;
$secondary100: #017FFF;
$additionalError100: #E32F27;
$additionalError8: #FDEEEE;
$additionalSuccess100: #22C96D;
$additionalSuccess8: #EEFDF4;
$cta100: #E14141;
$base100: #050A1F;
$base80: #4E5674;
$base60: #888D9F;
$base40: #DFE2E7;
$base30: #ECF0F5;
$base20: #EDEEF2;
$base8: #F4F5F9;
$base0: #FFFFFF;

.c_prim120 {
    color: $primary120;
}

.c_prim100 {
    color: $primary100;
}

.c_prim8 {
    color: $primary8;
}

.c_second100 {
    color: $secondary100;
}

.c_AE100 {
    color: $additionalError100;
}

.c_AE8 {
    color: $additionalError8;
}

.c_AS100 {
    color: $additionalSuccess100;
}

.c_AS8 {
    color: $additionalSuccess8;
}

.c_cta100 {
    color: $cta100;
}

.c_base100 {
    color: $base100;
}

.c_base80 {
    color: $base80;
}

.c_base60 {
    color: $base60;
}

.c_base40 {
    color: $base40;
}

.c_base20 {
    color: $base20;
}

.c_base8 {
    color: $base8;
}

.c_base0 {
    color: $base20;
}

:export {
    primary120: $primary120;
    primary100: $primary100;
    primary10: $primary10;
    primary8: $primary8;
    additionalError100: $additionalError100;
    additionalError8: $additionalError8;
    additionalSuccess100: $additionalSuccess100;
    additionalSuccess8: $additionalSuccess8;
    base100: $base100;
    base80: $base80;
    base60: $base60;
    base40: $base40;
    base30: $base30;
    base20: $base20;
    base8: $base8;
    base0: $base0;
}